/******* screen-medium.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* layout-medium.less 2013-1-16 *******/
.desk {
  max-width: 94%;
}
.desk.small {
  width: 600px;
}
#home {
  width: 146px;
  margin: 20px 0 16px 40px;
}
.cb-layout2 #head {
  margin-top: 100px;
}
.wave {
  width: 250px;
  top: 44px;
}
.togglenavigation {
  right: 3%;
  top: 18px;
}
.cb-layout1 .moodshadow {
  display: block;
}
h1 {
  padding: 0 3%;
}
@media only screen and (max-width: 1023px) {
  .bookingmaskwidth {
    margin-top: -50px !important;
  }
  .cb-layout2 .bookingmaskwidth {
    margin-top: 0 !important;
    top: 0;
  }
  .bookingform {
    display: none;
  }
  #bookingmask {
    height: 50px;
  }
}
.area {
  width: 100%;
}
.area > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area h2,
.area .foot {
  margin-right: 1.92307692%;
  margin-left: 1.92307692%;
}
.area .part,
.area > .grid table {
  margin-right: 1.92307692%;
  margin-left: 1.92307692%;
  width: 96.15384615%;
}
.area .tiny {
  width: 46.15384615%;
}
.area > .slim {
  width: 50%;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 3.84615385%;
  margin-left: 3.84615385%;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 92.30769231%;
}
.area > .slim .tiny {
  width: 92.30769231%;
}
.cb-layout2 .main {
  width: 100%;
}
.cb-layout2 .main > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 1.69491525%;
  margin-left: 1.69491525%;
  width: 96.61016949%;
}
.cb-layout2 .main .tiny {
  width: 46.61016949%;
}
.cb-layout2 .main > .slim {
  width: 50%;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 3.38983051%;
  margin-left: 3.38983051%;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 93.22033898%;
}
.cb-layout2 .main > .slim .tiny {
  width: 93.22033898%;
}
.main > .wide.form .tile .name,
.main > .wide a.capt {
  float: left;
  width: 33%;
}
.main > .wide.form .tile .chop,
.main > .wide.form .tile textarea,
.main > .wide.form .tile input.text,
.main > .wide.form .tile .ctrl,
.main > .wide input.capt {
  float: right;
  width: 63%;
}
.main {
  width: 104%;
  margin-left: -2%;
}
.cb-layout2 .main {
  width: 71.95121951%;
  margin-left: -1.2195122%;
}
.base,
.south {
  width: 102.43902439%;
  margin-left: -1.2195122%;
}
#root div.farnorth {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}
div.base div.unit,
div.south div.unit {
  margin-left: 1.19047619%;
  margin-right: 1.19047619%;
}
div.base div.unit.wide,
div.south div.unit.wide {
  width: 97.61904762%;
}
div.base div.unit.wide div.head h2,
div.south div.unit.wide div.head h2,
div.base div.unit.wide div.part,
div.south div.unit.wide div.part {
  margin-left: 3.75782881%;
  margin-right: 3.75782881%;
}
div.base div.unit.wide div.tall,
div.south div.unit.wide div.tall {
  width: 92.48434238%;
}
div.base div.unit.wide div.tiny,
div.south div.unit.wide div.tiny {
  width: 42.48434238%;
}
div.base div.unit.slim,
div.south div.unit.slim {
  width: 47.61904762%;
}
div.base div.unit.slim div.head h2,
div.south div.unit.slim div.head h2,
div.base div.unit.slim div.part,
div.south div.unit.slim div.part {
  margin-left: 7.92951542%;
  margin-right: 7.92951542%;
}
div.base div.unit.slim div.part,
div.south div.unit.slim div.part {
  width: 84.14096916%;
}
#disp {
  left: 20%;
  margin-left: 0;
  width: 60%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 100px !important;
}
#disp.zoom,
#disp.tube {
  left: 5%;
  margin-left: 0;
  width: 90%;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
h1,
h2 {
  overflow-wrap: normal;
  word-wrap: normal;
  -webkit-hyphens: none;
  -moz-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
/*# sourceMappingURL=./screen-medium.css.map */